import * as React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { BlockIconDisplayQuery } from "../../fragments/NodeBlockIconDisplayFields";
import { LinkPosition } from "../Blocks/ImageCopy";
import IconHash from "../Icons/IconHash";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";

const IconBlockContainer = styled(Container)`
  background: ${colors.white.getHex()};
  color: white;
  padding: 0;
  .title-col {
    margin: 0 0 2rem;
  }
  .icon-row {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0 10%;
  }
  .row {
    width: 100%;
  }
  h5 {
    font-size: 26px;
  }

  @media only screen and (max-width: 850px) {
    h5 {
      font-size: 22px;
    }
  }
`;

const IconCol = styled(Col)`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  div {
    margin: 1rem;
  }
`;

const BackgroundWrapper = styled.div`
  background: ${colors.primary.getHex()};
  clip-path: polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%);
  width: 100%;
  height: 100%;
  padding: 5rem 0 7rem;
  @media only screen and (max-width: 767px) {
    clip-path: polygon(0 0, 100% 0, 100% 97%, 50% 100%, 0 97%);
  }
`;

export const IconBlock = ({
  iconDisplayData,
  id
}: {
  iconDisplayData: BlockIconDisplayQuery;
  id: string;
}) => {
  return (
    <IconBlockContainer fluid>
      <LinkPosition id={id} />
      <BackgroundWrapper>
        <Row>
          <Col className="title-col">
            <Typography.H2 style={{ textAlign: "center" }}>
              {iconDisplayData.title}
            </Typography.H2>
          </Col>
        </Row>
        <Fade direction="down" triggerOnce>
          <Row className="icon-row" delay={100}>
            {Array.isArray(iconDisplayData.icons) &&
              iconDisplayData.icons.map((snippet, i) => {
                // const iconImage = new Img(snippet.icon);
                return (
                  <IconCol key={`snippet ${i}`} lg={3} md={3}>
                    {/* <WmkImage image={iconImage} /> */}
                    <IconHash icon={snippet.contentfulid.toLocaleLowerCase()} />
                    <Typography.H5>{snippet.copy}</Typography.H5>
                  </IconCol>
                );
              })}
          </Row>
        </Fade>
      </BackgroundWrapper>
    </IconBlockContainer>
  );
};
