import * as React from "react";
import { graphql } from "gatsby";
import { SiteMetaDataFields } from "../fragments/NodeSiteMetadataFields";
import { WmkSeo } from "wmk-seo";
import { LinkQuery } from "../fragments/NodeLinkFields";
import { BlockContactQuery } from "../fragments/NodeContactFields";
import { BlockHiringFormQuery } from "../fragments/NodeBlockHiringForm";
import { BlockImageCopyQuery } from "../fragments/NodeBlockImageCopyFields";
import { MenuQuery } from "wmk-menu";
import { ImageCopy } from "../components/Blocks/ImageCopy";
import HiringForm from "../components/Blocks/HiringForm";
import { IconBlock } from "../components/IconDisplay/IconBlock";
import ContactForm from "../components/Blocks/ContactForm";
import { BlockIconDisplayQuery } from "../fragments/NodeBlockIconDisplayFields";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { Fade } from "react-awesome-reveal";

const IndexPage = ({ data }: { data: IndexPageQuery }) => {
  const image = new Img(data.image);
  return (
    <>
      <WmkSeo.Meta
        title={"Home"}
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug="/"
      />
      <Fade direction="down" triggerOnce>
        <WmkImage image={image} />
      </Fade>
      <ImageCopy imageCopyData={data.about} id="about" />
      <HiringForm hiringFormData={data.hiringForm} />
      <ImageCopy imageCopyData={data.capabilities} id="capabilities" />
      <IconBlock iconDisplayData={data.iconDisplay} id="industries-served" />
      <ContactForm id="contact" />
    </>
  );
};

export default IndexPage;

interface IndexPageQuery {
  site: SiteMetaDataFields;
  image: ContentfulImageQuery;
  link: LinkQuery;
  contact: BlockContactQuery;
  hiringForm: BlockHiringFormQuery;
  about: BlockImageCopyQuery;
  capabilities: BlockImageCopyQuery;
  menu: MenuQuery;
  iconDisplay: BlockIconDisplayQuery;
}

export const query = graphql`
  {
    site {
      ...NodeSiteMetadata
    }
    image: contentfulAsset(title: { eq: "CoverPic-edited" }) {
      ...NodeImageQuery
    }
    link: contentfulLink(title: { eq: "Contact" }) {
      ...NodeLinkFields
    }
    contact: contentfulBlockContact(contentfulid: { eq: "Schema" }) {
      ...NodeBlockContactFields
    }
    hiringForm: contentfulBlockHiringForm(contentfulid: { eq: "Schema" }) {
      ...NodeBlockHiringFormFields
    }
    about: contentfulBlockImageCopy(contentfulid: { eq: "About" }) {
      ...NodeBlockImageCopyFields
    }
    capabilities: contentfulBlockImageCopy(
      contentfulid: { eq: "Capabilities" }
    ) {
      ...NodeBlockImageCopyFields
    }
    menu: contentfulMenu(title: { eq: "Schema" }) {
      ...NodeMenuFields
    }
    iconDisplay: contentfulBlockIconDisplay(
      contentfulid: { eq: "Industries" }
    ) {
      ...NodeBlockIconDisplayFields
    }
  }
`;
