import * as React from "react";

const CarIcon = ({ width = "84", height = "49" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 84 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_219_16)">
        <path
          d="M78.2023 20.0255L63.3073 15.1129C62.8475 14.9677 62.3998 14.7257 62.0973 14.4232L52.8408 3.8962C50.2635 1.3794 46.8755 0 43.2696 0H21.284C15.7785 0 10.2609 0.5808 8.57895 7.4657L3.42435 26.2812C1.43995 26.8862 -0.0241485 29.3062 5.15036e-05 31.944C0.0121515 33.6138 0.653452 35.1868 1.80295 36.3726C3.19445 37.8125 5.22725 38.6353 7.82875 38.8168C8.16755 41.382 9.40175 43.7415 11.3257 45.4476C13.2738 47.1779 15.8027 48.1338 18.4284 48.1338C21.0541 48.1338 23.5709 47.19 25.519 45.4597C27.4429 43.7536 28.6892 41.4183 29.028 38.8531H51.0257C51.3525 41.4304 52.5867 43.7899 54.5227 45.496C56.4708 47.2263 58.9997 48.1822 61.6496 48.1822C64.2995 48.1822 66.8284 47.2263 68.7765 45.496C70.7004 43.7899 71.9346 41.4304 72.2734 38.8531H79.4003C81.6146 38.8531 83.4054 37.0623 83.4054 34.848V27.0435C83.4054 23.7886 81.3242 20.9693 78.2145 20.0255H78.2023ZM61.6496 45.8832C57.0516 45.8832 53.361 42.2774 53.2522 37.6673C53.2401 37.0381 52.7319 36.542 52.1027 36.542H27.9874C27.3582 36.542 26.8621 37.026 26.8379 37.6552C26.6927 42.229 23.0022 45.8227 18.4405 45.8227C13.8788 45.8227 10.1883 42.2411 10.0431 37.6552C10.0189 37.026 9.52275 36.542 8.89355 36.542C4.79165 36.542 2.33535 34.7996 2.29905 31.9077C2.27485 30.1411 3.29125 28.4592 4.36815 28.435C4.87635 28.435 5.32405 28.072 5.45715 27.588L8.10705 17.9201L10.8054 8.0465C11.9791 3.2186 15.3913 2.299 21.284 2.299H43.2576C46.2463 2.299 49.0535 3.4243 51.1589 5.4813L60.4154 16.0083C61.0325 16.6133 61.7948 17.061 62.6055 17.303L77.5006 22.2156C79.6423 22.869 81.0822 24.805 81.0822 27.0314V34.8359C81.0822 35.7797 80.3199 36.542 79.3761 36.542H71.1844C70.5552 36.542 70.047 37.0381 70.0349 37.6673C69.9139 42.2774 66.2234 45.8832 61.6375 45.8832H61.6496Z"
          fill="white"
        />
        <path
          d="M54.6921 16.4559H39.8938C39.2646 16.4559 38.7443 16.9762 38.7443 17.6054C38.7443 18.2346 39.2646 18.7549 39.8938 18.7549H54.6921C55.3213 18.7549 55.8416 18.2346 55.8416 17.6054C55.8416 16.9762 55.3213 16.4559 54.6921 16.4559Z"
          fill="white"
        />
        <path
          d="M31.8713 6.63075C31.2421 6.63075 30.7218 7.15105 30.7218 7.78025V16.456H15.3427C14.7135 16.456 14.1932 16.9763 14.1932 17.6055C14.1932 18.2347 14.7135 18.755 15.3427 18.755H31.8713C32.5005 18.755 33.0208 18.2347 33.0208 17.6055V7.76815C33.0208 7.13895 32.5005 6.61865 31.8713 6.61865V6.63075Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_219_16">
          <rect width="83.3932" height="48.1822" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarIcon;
