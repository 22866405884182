import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { BlockHiringFormQuery } from "../../fragments/NodeBlockHiringForm";
import { colors } from "../ui/colors";
import { Typography } from "../ui/Typography";
import HubspotForm from "react-hubspot-form";

const FormContainer = styled(Container)`
  background: ${colors.primary.getHex()};
  color: ${colors.white.getHex()};
  padding: 1rem 20vw;
  .col {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    padding: 1rem 10vw;
    h2 {
      margin-bottom: 2rem;
    }
  }
  @media only screen and (max-width: 455px) {
    padding: 1rem;
  }
`;

// const FormContainerWrapper = styled(Container)`
//   background: ${colors.primary.getHex()};
// `;

const HiringForm = ({
  hiringFormData
}: {
  hiringFormData: BlockHiringFormQuery;
}) => {
  return (
    <>
      {/* <FormContainerWrapper fluid> */}
      <FormContainer fluid>
        <Row>
          <Col>
            <Typography.H2>{hiringFormData.header}</Typography.H2>
            <Typography.P>{hiringFormData.copy.copy}</Typography.P>
          </Col>
        </Row>
        <Row>
          <Col>
            <HubspotForm
              portalId={"22488509"}
              formId={"f5fb494a-038a-43da-ae40-da773aff9280"}
            />
          </Col>
        </Row>
      </FormContainer>
      {/* </FormContainerWrapper> */}
    </>
  );
};

export default HiringForm;
