import * as React from "react";

const SiloIcon = ({ width = "59", height = "82" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 59 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_261_2)">
        <path
          d="M51.11 24.17C50.68 24.17 50.31 23.85 50.24 23.42C50.16 22.88 48.37 10.17 48.19 8.12001C48.07 6.75001 47.39 6.11001 45.71 5.73001C45.04 5.58001 42.97 5.04001 40.58 4.41001C36.88 3.44001 32.26 2.23001 31.05 2.01001C29.04 1.64001 28.45 1.68001 27.35 1.99001C26.7 2.18001 23.76 2.89001 20.66 3.64001C17.41 4.43001 14.05 5.24001 13.02 5.53001C11.24 6.01001 10.46 6.87001 10.31 8.49001C10.12 10.54 8.32999 22.9 8.25999 23.42C8.18999 23.9 7.74999 24.23 7.26999 24.16C6.78999 24.09 6.45999 23.65 6.52999 23.17C6.54999 23.04 8.38999 10.33 8.56999 8.33001C8.77999 5.99001 10.09 4.52001 12.56 3.84001C13.62 3.55001 16.99 2.73001 20.25 1.94001C23.2 1.22001 26.26 0.480009 26.87 0.310009C28.31 -0.0999911 29.19 -0.109991 31.36 0.290009C32.64 0.520009 37.1 1.69001 41.02 2.72001C43.4 3.34001 45.45 3.88001 46.09 4.02001C47.62 4.36001 49.68 5.14001 49.93 7.96001C50.11 9.96001 51.95 23.04 51.97 23.17C52.04 23.65 51.7 24.09 51.23 24.16C51.19 24.16 51.15 24.16 51.11 24.16V24.17Z"
          fill="white"
        />
        <path
          d="M51.11 81.1H7.39001C6.91001 81.1 6.51001 80.71 6.51001 80.22V23.29C6.51001 22.81 6.90001 22.41 7.39001 22.41H51.11C51.59 22.41 51.99 22.8 51.99 23.29V80.22C51.99 80.7 51.6 81.1 51.11 81.1ZM8.26001 79.35H50.23V24.17H8.26001V79.35Z"
          fill="white"
        />
        <path
          d="M57.62 81.1H0.88C0.4 81.1 0 80.71 0 80.22C0 79.73 0.39 79.34 0.88 79.34H57.62C58.1 79.34 58.5 79.73 58.5 80.22C58.5 80.71 58.11 81.1 57.62 81.1Z"
          fill="white"
        />
        <path
          d="M13.9 81.1C13.42 81.1 13.02 80.71 13.02 80.22V23.29C13.02 22.81 13.41 22.41 13.9 22.41C14.39 22.41 14.78 22.8 14.78 23.29V80.22C14.78 80.7 14.39 81.1 13.9 81.1Z"
          fill="white"
        />
        <path
          d="M25.62 81.1C25.14 81.1 24.74 80.71 24.74 80.22V23.29C24.74 22.81 25.13 22.41 25.62 22.41C26.11 22.41 26.5 22.8 26.5 23.29V80.22C26.5 80.7 26.11 81.1 25.62 81.1Z"
          fill="white"
        />
        <path
          d="M25.62 33.84H13.9C13.42 33.84 13.02 33.45 13.02 32.96C13.02 32.47 13.41 32.08 13.9 32.08H25.62C26.1 32.08 26.5 32.47 26.5 32.96C26.5 33.45 26.11 33.84 25.62 33.84Z"
          fill="white"
        />
        <path
          d="M25.62 43.52H13.9C13.42 43.52 13.02 43.13 13.02 42.64C13.02 42.15 13.41 41.76 13.9 41.76H25.62C26.1 41.76 26.5 42.15 26.5 42.64C26.5 43.13 26.11 43.52 25.62 43.52Z"
          fill="white"
        />
        <path
          d="M25.62 53.24H13.9C13.42 53.24 13.02 52.85 13.02 52.36C13.02 51.87 13.41 51.48 13.9 51.48H25.62C26.1 51.48 26.5 51.87 26.5 52.36C26.5 52.85 26.11 53.24 25.62 53.24Z"
          fill="white"
        />
        <path
          d="M25.62 62.31H13.9C13.42 62.31 13.02 61.92 13.02 61.43C13.02 60.94 13.41 60.55 13.9 60.55H25.62C26.1 60.55 26.5 60.94 26.5 61.43C26.5 61.92 26.11 62.31 25.62 62.31Z"
          fill="white"
        />
        <path
          d="M25.62 72.21H13.9C13.42 72.21 13.02 71.82 13.02 71.33C13.02 70.84 13.41 70.45 13.9 70.45H25.62C26.1 70.45 26.5 70.84 26.5 71.33C26.5 71.82 26.11 72.21 25.62 72.21Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_2">
          <rect width="58.49" height="81.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SiloIcon;
