import * as React from "react";
import styled from "styled-components";
import CarIcon from "./CarIcon";
import GearIcon from "./GearIcon";
import SiloIcon from "./SiloIcon";
import FoundryIcon from "./FoundryIcon";

interface Props {
  $isHovered?: boolean;
}

const IconWrapper = styled.div<Props>`
  transition: all 0.3s ease;
  ${({ $isHovered }) => ($isHovered ? `transform: scale(1.3)` : ``)};
  transition: all 0.3s ease;
`;

const IconHash = ({
  icon,
  height,
  width,
  isHovered
}: {
  icon: string;
  height?: string;
  width?: string;
  isHovered?: boolean;
}) => {
  const iconDisplays: { [key: string]: React.ReactElement } = {
    car: <CarIcon height={height} width={width} />,
    gear: <GearIcon height={height} width={width} />,
    silo: <SiloIcon height={height} width={width} />,
    foundry: <FoundryIcon height={height} width={width} />
  };
  const currentIcon = iconDisplays[icon]
    ? iconDisplays[icon]
    : iconDisplays.gear;

  return <IconWrapper $isHovered={isHovered}>{currentIcon}</IconWrapper>;
};

export default IconHash;
