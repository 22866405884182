import * as React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Img, WmkImage } from "wmk-image";
import { BlockImageCopyQuery } from "../../fragments/NodeBlockImageCopyFields";
import { Typography } from "../ui/Typography";
import Markdown from "markdown-to-jsx";
import { Fade } from "react-awesome-reveal";

const StyledContainer = styled(Container)<{ $direction?: string }>`
  display: flex;
  flex-direction: column;
    ${({ $direction }) =>
      $direction === `Right`
        ? `.column:nth-child(1) {order: 1;}
    .column:nth-child(2) {order: 2;}`
        : `.column:nth-child(1) {order: 2;}
    .column:nth-child(2) {order: 1;}`};
  }
  @media only screen and (max-width: 768px) {
    .column:nth-child(1) {
      order:1;
    }
    .column:nth-child(2) {
      order:2;
    }
  }
`;

const TextCol = styled(Col)`
  padding: 2rem 5vw 1rem;
  @media only screen and (min-width: 767px) and (max-width: 1000px) {
    h2 {
      font-size: 28px !important;
    }
  }
`;

const MarkdownWrapper = styled.div`
  strong {
    font-weight: bold;
  }
  .p {
    line-height: 26px;
    margin-bottom: 1rem;
  }
  li > .p {
    margin-bottom: 0.2rem;
  }
`;

export const LinkPosition = styled.div`
  position: relative;
  top: -112px;
`;

export const ImageCopy = ({
  imageCopyData,
  id
}: {
  imageCopyData: BlockImageCopyQuery;
  id: string;
}) => {
  // console.log(imageCopyData);
  const { imagePosition } = imageCopyData;

  const ModifiedLi = ({ children, ...props }) => (
    <li {...props}>
      <Typography.P>{children}</Typography.P>
    </li>
  );

  return (
    <>
      <StyledContainer fluid $direction={imagePosition}>
        <LinkPosition id={id} />
        <Row className="ordered-row">
          <TextCol
            // md={imagePosition === "Right" ? 4 : { span: 4, offset: 1 }}
            md={5}
            className="column">
            <Fade
              direction={
                imagePosition ? imagePosition.toLocaleLowerCase() : "left"
              }
              delay={100}
              triggerOnce>
              <Typography.BLOCKQUOTE>
                {imageCopyData?.subheader}
              </Typography.BLOCKQUOTE>
              <Typography.H2>{imageCopyData?.header}</Typography.H2>
              <MarkdownWrapper>
                <Markdown
                  options={{
                    overrides: {
                      p: {
                        component: Typography.P
                      },
                      li: {
                        component: ModifiedLi
                      }
                    }
                  }}>
                  {imageCopyData?.content?.content}
                </Markdown>
              </MarkdownWrapper>
            </Fade>
          </TextCol>
          <Col
            // md={imagePosition === "Right" ? { span: 7, offset: 1 } : 7}
            md={7}
            className="column"
            style={{ paddingLeft: 0, paddingRight: 0 }}>
            <WmkImage image={new Img(imageCopyData.image)} />
          </Col>
        </Row>
      </StyledContainer>
    </>
  );
};

// export default ImageCopy;
