import * as React from "react";

const FoundryIcon = ({ width = "75", height = "70" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_261_13)">
        <path
          d="M73.71 69.85H0.88C0.4 69.85 0 69.46 0 68.97V49.81L2.42 13.1C2.45 12.64 2.83 12.28 3.29 12.28H8.78C9.24 12.28 9.62 12.63 9.65 13.09L12.16 47.02L33.68 35.73C33.95 35.59 34.28 35.6 34.54 35.76C34.8 35.92 34.96 36.2 34.96 36.51V46.98L56.47 35.83C56.74 35.69 57.07 35.7 57.33 35.86C57.59 36.02 57.75 36.3 57.75 36.61V52.57H73.71C74.19 52.57 74.59 52.96 74.59 53.45V68.99C74.59 69.47 74.2 69.87 73.71 69.87V69.85ZM1.75 68.09H72.84V54.3H56.88C56.4 54.3 56 53.91 56 53.42V38.02L34.49 49.17C34.22 49.31 33.89 49.3 33.63 49.14C33.37 48.98 33.21 48.7 33.21 48.39V37.93L11.79 49.16C11.53 49.3 11.22 49.29 10.96 49.16C10.7 49.02 10.53 48.75 10.51 48.46L7.97 14.04H4.11L1.75 49.87V68.1V68.09Z"
          fill="white"
        />
        <path
          d="M16.2199 63.9H7.56994C7.08994 63.9 6.68994 63.51 6.68994 63.02V56.97C6.68994 56.49 7.07994 56.09 7.56994 56.09H16.2199C16.6999 56.09 17.0999 56.48 17.0999 56.97V63.02C17.0999 63.5 16.7099 63.9 16.2199 63.9ZM8.43994 62.15H15.3399V57.85H8.43994V62.15Z"
          fill="white"
        />
        <path
          d="M33.3401 63.9H24.6901C24.2101 63.9 23.8101 63.51 23.8101 63.02V56.97C23.8101 56.49 24.2001 56.09 24.6901 56.09H33.3401C33.8201 56.09 34.2201 56.48 34.2201 56.97V63.02C34.2201 63.5 33.8301 63.9 33.3401 63.9ZM25.5601 62.15H32.4601V57.85H25.5601V62.15Z"
          fill="white"
        />
        <path
          d="M50.0801 63.9H41.43C40.95 63.9 40.55 63.51 40.55 63.02V56.97C40.55 56.49 40.94 56.09 41.43 56.09H50.0801C50.5601 56.09 50.96 56.48 50.96 56.97V63.02C50.96 63.5 50.5701 63.9 50.0801 63.9ZM42.3 62.15H49.2001V57.85H42.3V62.15Z"
          fill="white"
        />
        <path
          d="M29.23 16.85C28.93 16.85 28.63 16.83 28.33 16.79C26.84 16.59 24.83 15.77 23.69 13.03C23.46 12.37 23.36 12.24 23.33 12.2C23.25 12.17 22.86 12.17 22.52 12.17H22.27C20.4 12.17 17.16 10.04 16.19 8.16C15.64 7.1 13.38 6.17 11.39 6.6C10.23 6.85 8.83 7.65 8.72 10.03C8.7 10.51 8.31 10.9 7.8 10.86C7.32 10.84 6.94 10.43 6.97 9.94C7.1 7.26 8.57 5.41 11.01 4.88C13.62 4.31 16.76 5.46 17.74 7.35C18.46 8.74 21.13 10.41 22.26 10.41H22.5C24.05 10.42 24.69 10.57 25.32 12.4C25.92 13.85 26.94 14.75 28.33 15.01C30.37 15.39 32.54 14.31 32.84 14C33.66 12.94 34.42 12.21 36.45 12.78C37.17 12.98 39.71 12.84 41.46 11.33C42.79 10.19 43.32 8.53 43.06 6.38C42.79 4.13 41.76 2.68 40 2.07C37.53 1.22 34.47 2.27 34.06 2.68C32.53 4.34 30.38 4.45 28.58 2.94C27.31 1.88 24.39 1.75 22.39 3.17C20.01 4.86 18.83 4.31 17.53 3.43L17.31 3.28C14.22 1.59 11.29 1.41 8.9 2.8C6.02 4.46 4.67 7.86 4.62 9.74C4.61 10.22 4.21 10.59 3.72 10.6C3.24 10.59 2.85 10.19 2.87 9.71C2.93 7.04 4.76 3.18 8.02 1.3C10.09 0.0999982 13.52 -0.780002 18.22 1.79L18.53 1.99C19.44 2.61 19.81 2.87 21.39 1.75C23.88 -0.0200016 27.69 -0.0800017 29.71 1.61C31.11 2.77 32.15 2.19 32.78 1.5C33.67 0.529998 37.43 -0.600002 40.43 0.369998C42.17 0.929998 44.35 2.39 44.81 6.17C45.23 9.61 43.84 11.59 42.61 12.66C40.34 14.61 37.18 14.8 35.99 14.47C34.93 14.18 34.83 14.3 34.27 15.02C33.67 15.79 31.5 16.85 29.23 16.85Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_13">
          <rect width="74.59" height="69.85" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FoundryIcon;
